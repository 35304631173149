import loadable from '@loadable/component';
import React from 'react';

import { Icon } from './Icon';
const LazyTooltip = loadable(async () => {
  const module = await import('./Tooltip');
  return module.Tooltip;
});
export const Tooltip = (props) => (
  <LazyTooltip
    {...props}
    fallback={
      <span>
        {React.isValidElement(props.icon) ? (
          props.icon
        ) : (
          <Icon icon={props.icon || 'question-circle'} color="#a4a7c6" />
        )}
      </span>
    }
  />
);
