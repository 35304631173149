import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Section } from 'src/components/molecules/Section';
import { Icon } from 'src/components/atoms/Icon';
import { Tooltip } from 'src/components/atoms/LazyTooltip';
import productTable from './product-comparison.json5';

export const TableContainer = styled.div`
  overflow: auto;
  margin-bottom: 1.75rem;
  border-radius: 0.75rem;
  border: 1px solid #e5e5e5;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);

  th[scope='row'] {
    font-weight: normal;
    background: none;
  }

  table {
    margin: 0;
  }

  thead tr:first-child {
    > th,
    > td {
      border-top: 0;
    }
  }

  tr:last-child {
    > th,
    > td {
      border-bottom: 0;
    }
  }

  td:first-child,
  th:first-child {
    border-left: 0;
  }

  td:last-child,
  th:last-child {
    border-right: 0;
  }
`;

TableContainer.displayName = 'TableContainer';

export default function LegacySection({ heading, customData }) {
  customData = JSON.parse(customData);
  return (
    <Section {...customData?.flags}>
      <h2 className="highlight">{heading}</h2>
      <TableContainer>
        <table>
          <thead>
            <tr>
              {productTable.header.values.map((value) => (
                <th scope="col" key={value}>
                  {value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {productTable.sections.map((section) => (
              <Fragment key={section.heading}>
                <tr>
                  <th colSpan="4" scope="col">
                    {section.heading}
                  </th>
                </tr>
                {section.rows.map((row) => (
                  <tr key={row.heading}>
                    <th scope="row">
                      {row.heading}
                      <Tooltip title={row.tooltip} />
                    </th>
                    {row.values.map((value, k) => (
                      <td key={k}>{value ? <Icon icon="check" color="#02b361" size="lg" center /> : null}</td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Section>
  );
}
